import React from 'react'
import { MAIN_PHONE_NUMBER, MAIN_PHONE_NUMBER_LINK } from '@constants/index'
import { Container } from '@shared/components/container'
import { FullLogo } from '@shared/components/logo'
import { TopMenuLogin } from '@shared/components/top-menu-v2/shared/login'
import Link from 'next/link'

import { useRegions } from 'regions/provider'
import { BurgerV2 } from './shared/burger-v2'
import { TopMenuListV2 } from './shared/menu-list-v2'
import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	onOpenPopupMenu,
	hideLinksList = false,
	content,
	disableRedirectOnLogo = false,
}: IStateProps) => {
	const regions = useRegions()

	return (
		<header className={styles.wrap}>
			<Container className={styles.container}>
				{content ? (
					content
				) : (
					<>
						<div className={styles.logoWrap}>
							{disableRedirectOnLogo ? (
								<FullLogo className={styles.logo} />
							) : (
								<Link href='/' aria-label='Перейти на главную страницу'>
									<FullLogo className={styles.logo} />
									<span className='visually-hidden'>Главная страница</span>
								</Link>
							)}
						</div>
						<TopMenuListV2 hideLinksList={hideLinksList} className={styles.menuList} />
						<div className={styles.controls}>
							<button onClick={regions.openPicker} type='button' className={styles.city}>
								{regions.currentCity.displayName}
							</button>
							<div className={styles.phone}>
								<a href={`tel:${MAIN_PHONE_NUMBER_LINK}`}>{MAIN_PHONE_NUMBER}</a>
								<span>Звонок по России бесплатно</span>
							</div>
							<TopMenuLogin />
							<BurgerV2 onClick={onOpenPopupMenu} active={false} className={styles.burger} />
						</div>
					</>
				)}
			</Container>
		</header>
	)
}
